import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";
import BaseChart from "./BaseChart";
import { baseChartConfig, formatShortDate } from "./chartConfig";
import { NoMatchesYet } from "~/components/NoEventsYet";
import type { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";

type Props = {
  matches: IMatchStats[];
  activeTeam?: ITeam;
  limitMatches?: boolean;
  graphHeight?: number;
};

export default memo(function ChartGoalDifference({
  matches = [],
  limitMatches,
  graphHeight = 222,
}: Props) {
  const { t } = useTranslation();
  const [theme] = useTheme();

  const sortedMatches = useMemo(
    () => (limitMatches ? matches.slice(-10) : matches),
    [matches, limitMatches]
  );

  const chartData = useMemo(() => {
    let total = 0;
    return {
      labels: matches.map((match) => {
        const date = formatShortDate(match.scheduledAtUtc);
        // Get opponent name, using shortName when available, otherwise full name
        const opponentName =
          match.opponent?.organization?.shortName ||
          match.opponent?.name ||
          t("statsPages.unknownOpponent");

        // Format as "Date - Opponent"
        return `${date} - ${opponentName}`;
      }),
      datasets: [
        {
          type: "line",
          label: t("statsPages.graphTitleGoalDifference"),
          data: matches.map((m) => {
            total += (m.score?.team || 0) - (m.score?.opponent || 0);
            return total;
          }),
          borderColor: "#68F3CB",
          backgroundColor: "rgba(104, 243, 203, 0.1)",
          fill: true,
          tension: 0, // Added tension: 0 for straight lines
          pointRadius: 0,
        },
        {
          type: "bar",
          label: t("statsPages.graphTitleGoalDifferencePerMatch"),
          data: matches.map(
            (m) => (m.score?.team || 0) - (m.score?.opponent || 0)
          ),
          backgroundColor: "#A855F7",
        },
      ],
    };
  }, [sortedMatches, t]);

  /**
   * Chart configuration options
   */
  const options = useMemo(
    () => ({
      ...baseChartConfig(theme),
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            // Enhanced tooltip to show goal difference details
            label: function (context: any) {
              const datasetIndex = context.datasetIndex;
              const value = context.raw !== undefined ? context.raw : 0;

              if (datasetIndex === 0) {
                // Line chart (cumulative)
                return `${t("statsPages.totalGoalDifference", "Total")}: ${
                  value > 0 ? "+" : ""
                }${value}`;
              } else {
                // Bar chart (per match)
                return `${t("statsPages.matchGoalDifference", "Match")}: ${
                  value > 0 ? "+" : ""
                }${value}`;
              }
            },
          },
        },
      },
      scales: {
        x: {
          // Hide x-axis labels when limitMatches is true
          display: !limitMatches,
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 0,
          },
        },
        y: {
          // Always display y-axis
          display: true,
          beginAtZero: false,
          grid: {
            display: true,
          },
        },
      },
    }),
    [theme, limitMatches, t]
  );

  if (matches.length === 0) return <NoMatchesYet />;

  return (
    <BaseChart
      type="bar"
      data={chartData}
      options={options}
      height={graphHeight}
      width="100%"
    />
  );
});
